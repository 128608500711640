export interface MenuNode {
  label: string;
  url?: string;
  content?: string;
  icon?: string;
  level?: number;
  target?: string;
  locale?: string[];

  children?: MenuNode[];
  parents?: MenuNode[];
}
