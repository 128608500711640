import { Injectable, LOCALE_ID, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ComplianceNaviNode {
  title: string;
  url: string;
  locale?: string[];
  lastUpdateDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComplianceTocService {
  valueSubject = new BehaviorSubject<any>('');

  public nodes: ComplianceNaviNode[] = [
    {
      title: $localize`Regulatory Information`,
      url: 'compliance/ri',
      lastUpdateDate: '',
    },
    {
      title: $localize`Declaration of Conformity`,
      url: 'compliance/doc',
      lastUpdateDate: '',
    },
    {
      title: $localize`Safety Data Sheet`,
      url: 'compliance/sds',
      lastUpdateDate: '',
    },
    {
      title: $localize`Open Source Software`,
      url: 'compliance/oss',
      lastUpdateDate: 'September 17, 2024',
    },
    {
      title: 'RoHS',
      url: 'compliance/rohs',
      locale: ['zh-CN'],
      lastUpdateDate: 'January 7, 2020',
    },
    {
      title: $localize`Package Information`,
      url: 'compliance-search/pmi',
      locale: ['it'],
      lastUpdateDate: 'August 31, 2023',
    },
    {
      title: $localize`Package Information`,
      url: 'compliance/pmi',
      locale: ['fr'],
      lastUpdateDate: 'August 31, 2023',
    },
  ];

  constructor(private injector: Injector) {}

  getToc(): void {
    const locale = this.injector.get(LOCALE_ID);

    this.nodes = this.nodes.filter((node: any) => {
      if (node.locale !== undefined) {
        if (node.locale.indexOf(locale) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    this.valueSubject.next(this.nodes);
  }
}
